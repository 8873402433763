<template>
    <div :class="{'has-errors': hasErrors}"
         class="form-group multi-select"
         @click="dropdownVisible = !dropdownVisible">
        <select :id="id"
                v-model="value"
                :name="name"
                :title="name"
                class="hidden"
                multiple="multiple">
            <option v-for="option of options"
                    :selected="value.includes(option.value)"
                    :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <slot><!-- Put label here --></slot>
        <button class="dropdown-btn"
                type="button">
            <span>{{ btnText }}</span>
        </button>
        <div :class="{visible: dropdownVisible}"
             class="dropdown">
            <div v-if="allowSelectAll"
                 class="row">
                <div class="checkbox">
                    <input :id="'all-' + id"
                           :checked="everythingSelected"
                           :name="'fake___' + name"
                           type="checkbox"
                           value="all"
                           @change="onCheckboxChanged($event)">
                    <svg fill="none"
                         viewBox="0 0 8 6"
                         xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M7.866 0.931739C7.95547 1.02153 8.0002 1.13055 8.0002 1.25882C8.0002 1.38709 7.95547 1.49611 7.866 1.5859L3.74434 5.7225C3.65488 5.81229 3.54625 5.85718 3.41845 5.85718C3.29064 5.85718 3.18201 5.81229 3.09255 5.7225L0.705818 3.32712C0.616355 3.23733 0.571625 3.12831 0.571625 3.00004C0.571625 2.87177 0.616355 2.76275 0.705818 2.67296L1.35762 2.0188C1.44708 1.92901 1.55571 1.88412 1.68351 1.88412C1.81132 1.88412 1.91995 1.92901 2.00941 2.0188L3.41845 3.43775L6.56241 0.277579C6.65187 0.187791 6.7605 0.142899 6.88831 0.142899C7.01611 0.142899 7.12474 0.187791 7.21421 0.277579L7.866 0.931739Z"
                              fill="white"
                              fill-rule="evenodd" />
                    </svg>
                </div>
                <label :for="'all-' + id">
                    Alles selecteren
                </label>
            </div>
            <div v-for="option of options"
                 class="row">
                <div class="checkbox">
                    <input :id="option.value + '-' + id"
                           :checked="value.includes(option.value)"
                           :name="'fake___' + name"
                           :value="option.value"
                           type="checkbox"
                           @change="onCheckboxChanged($event)">
                    <svg fill="none"
                         viewBox="0 0 8 6"
                         xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                              d="M7.866 0.931739C7.95547 1.02153 8.0002 1.13055 8.0002 1.25882C8.0002 1.38709 7.95547 1.49611 7.866 1.5859L3.74434 5.7225C3.65488 5.81229 3.54625 5.85718 3.41845 5.85718C3.29064 5.85718 3.18201 5.81229 3.09255 5.7225L0.705818 3.32712C0.616355 3.23733 0.571625 3.12831 0.571625 3.00004C0.571625 2.87177 0.616355 2.76275 0.705818 2.67296L1.35762 2.0188C1.44708 1.92901 1.55571 1.88412 1.68351 1.88412C1.81132 1.88412 1.91995 1.92901 2.00941 2.0188L3.41845 3.43775L6.56241 0.277579C6.65187 0.187791 6.7605 0.142899 6.88831 0.142899C7.01611 0.142899 7.12474 0.187791 7.21421 0.277579L7.866 0.931739Z"
                              fill="white"
                              fill-rule="evenodd" />
                    </svg>
                </div>
                <label :for="option.value + '-' + id">
                    {{ option.label }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: "MultiSelect",
        model: {
            event: 'change',
            prop: 'initialValue',
        },
        props: {
            id: String,
            name: String,
            initialValue: Array,
            hasErrors: {
                type: Boolean,
                default: () => false,
            },
            allowSelectAll: {
                type: Boolean,
                default: () => true,
            },
        },
        data() {
            return {
                value: [],
                options: [],
                dropdownVisible: false,
            };
        },
        watch: {
            initialValue: {
                handler(value = []) {
                    this.value = value.map((i) => i.toString());
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            initOptions() {
                this.options = [];
                this.$slots.options.forEach((node) => {
                    if (node.tag !== 'option') {
                        return;
                    }

                    this.options.push({
                        label: node.children.reduce((str, childNode) => str + ' ' + childNode.text, '').trim(),
                        value: node.data.attrs.value,
                    });
                });
            },
            onCheckboxChanged(event) {
                const checkbox = event.target;
                const value = checkbox.value;
                const checked = checkbox.checked;

                if (value === 'all') {
                    if (checked) {
                        this.value = this.options.map((option) => option.value);
                    } else {
                        this.value = [];
                    }

                    this.$forceUpdate();
                    return;
                }

                if (this.value.includes(value) && checked === false) {
                    this.value.splice(this.value.indexOf(value), 1);
                } else if (!this.value.includes(value) && checked === true) {
                    this.value.push(value);
                }

                this.$forceUpdate();
            },
            getCloseDropdownHandler(el) {
                return (e) => {
                    if (e.target.isEqualNode(el)) {
                        this.dropdownVisible = false;
                    }
                };
            },
            setClickOutsideHandler(el) {
                if (el.parentNode == null) {
                    return;
                }
                this.setClickOutsideHandler(el.parentNode);
                el.parentNode.addEventListener('click', this.getCloseDropdownHandler(el.parentNode));
            },
            removeClickOutsideHandler(el) {
                if (el.parentNode == null) {
                    return;
                }
                this.setClickOutsideHandler(el.parentNode);
                el.parentNode.removeEventListener('click', this.getCloseDropdownHandler(el.parentNode));
            },
        },
        computed: {
            everythingSelected: {
                get() {
                    return this.value.length === this.options.length;
                },
            },
            nothingSelected: {
                get() {
                    return this.value.length === 0;
                },
            },
            btnText: {
                get() {
                    if (this.everythingSelected) {
                        return 'Alles geselecteerd';
                    }

                    if (this.nothingSelected) {
                        return this.$slots.button[0].text;
                    }

                    return this.value.reduce((str, value) => {
                        const item = this.options.find((opt) => opt.value === value);

                        if (item == null) {
                            return str;
                        }

                        if (str.length > 0) {
                            return str + ', ' + item.label;
                        }

                        return item.label;
                    }, '');
                },
            },
        },
        mounted() {
            this.initOptions();
            this.setClickOutsideHandler(this.$el);
        },
    });
</script>

<style lang="scss"
       scoped>
    $row-height : var(--row-height, 24px);

    .form-group.multi-select {
        .hidden {
            display : none;
        }

        .dropdown-btn {
            border           : 1px solid rgba(0, 0, 0, 0.1);
            border-radius    : 4px;
            width            : 100%;
            height           : 48px;
            padding-left     : 18px;
            background-color : white;
            cursor           : pointer;
            font-size        : 14px;
            font-weight      : 500;
            text-align       : left;
            color            : #1a1a1a;

            span {
                display       : inline-block;
                overflow      : hidden;
                width         : 80%;
                height        : 100%;
                line-height   : 44px;
                white-space   : nowrap;
                text-overflow : ellipsis;
            }
        }

        .dropdown {
            position   : absolute;
            bottom     : 48px;
            display    : none;
            flex-flow  : column nowrap;
            overflow   : auto;
            border     : 1px solid rgba(0, 0, 0, 0.1);
            width      : 100%;
            max-height : calc(#{$row-height} * 5 + 32px);
            padding    : 8px 0 24px 0;
            background : white;

            .row {
                margin-bottom : calc(#{$row-height} - 24px);
                height        : 24px;
                padding       : 4px 16px 4px 16px;
                cursor        : pointer;
                transition    : background-color .3s ease-out;


                * {
                    cursor : pointer;
                }

                .checkbox {
                    margin : 0 8px 0 0;

                    input[type=checkbox] {
                        box-shadow         : none;
                        border             : 1px solid #c44e57;
                        box-sizing         : border-box;
                        width              : 16px;
                        height             : 16px;
                        padding            : 0;
                        cursor             : pointer;
                        font-size          : 2em;
                        -webkit-appearance : none;
                        -moz-appearance    : none;
                        -o-appearance      : none;
                        appearance         : none;

                        &:checked {
                            background-color : #c44e57;
                        }
                    }

                    svg {
                        position : absolute;
                        top      : 0;
                        bottom   : 0;
                        left     : 0;
                        right    : 0;
                        margin   : auto;
                        width    : 8px;
                        pointer-events: none;
                    }
                }

                &:hover {
                    background : rgba(0, 0, 0, .1);
                }
            }

            .checkbox + label {
                padding-left: 24px;
                margin-left: -24px;
            }

            &.visible {
                display   : flex;
                flex-flow : column nowrap;
            }
        }
    }
</style>
