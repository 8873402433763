import Vue from 'vue';
import MultiSelect from "./components/MultiSelect";


Vue.config.productionTip = false;

new Vue({
    el: "#overlay",
    components: {
        MultiSelect,
    },
});

function ensureNoLostChanges() {
    let isEditting = false;

    function preventNav(e) {
        if (!isEditting) {
            return;
        }
        e.preventDefault();
        e.returnValue = '';
    }

    const forms = document.querySelectorAll('form');

    window.addEventListener('beforeunload', preventNav);

    forms.forEach((form) => {
        form.addEventListener('change', (e) => {
            if (form.dataset.hasOwnProperty('autoSubmit')) {
                window.removeEventListener('beforeunload', preventNav);
                return;
            }
            isEditting = true;
        });
        form.addEventListener('submit', () => {
            window.removeEventListener('beforeunload', preventNav);
        });
    });
}

window.addEventListener("load", function () {
    ensureNoLostChanges();
});
